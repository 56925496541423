import { type RefObject, useCallback, useEffect } from 'react'

type Event = MouseEvent | TouchEvent

interface UseClickOutsideParams<T extends HTMLElement = HTMLElement> {
  ref: RefObject<T | null>
  callback: (event: Event) => void
}

export const useClickOutside = <T extends HTMLElement = HTMLElement>({ ref, callback }: UseClickOutsideParams<T>) => {
  const listener = useCallback(
    (event: Event) => {
      const clickedElement = ref.current
      const targetElement = (event.target as Node) || null
      const isExternalLink = targetElement.parentElement?.parentElement?.getAttribute('target') === '_blank'

      if (!clickedElement || (!isExternalLink && clickedElement.contains(targetElement))) {
        return
      }

      if (isExternalLink) {
        setTimeout(() => callback(event), 500)
      } else {
        callback(event)
      }
    },
    [callback, ref]
  )

  useEffect(() => {
    document.addEventListener('mousedown', listener)
    document.addEventListener('touchstart', listener)

    return () => {
      document.removeEventListener('mousedown', listener)
      document.removeEventListener('touchstart', listener)
    }
  }, [listener])
}
